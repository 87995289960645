import React from 'react';
import moment from 'moment';

import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  PaymentOutlined,
  AccountBalanceOutlined,
  CloseOutlined,
} from '@material-ui/icons';

import { ReactComponent as OutflowIcon } from '../../assets/outflow-icon.svg';
import { ReactComponent as InputMoney } from '../../assets/input-money-icon.svg';

import currencyFormatter from '../../utils/currencyFormatter';
import { maskCPF } from '../../utils/maskCPF';
import { maskCNPJ } from '../../utils/maskCNPJ';

import { useDetailsStyles } from './styles';

export default function DetailsDrawer({
  onClose,
  details: { open, selected },
}) {
  const styles = useDetailsStyles();

  const date = moment(selected.timestamp);

  const isExit = selected.category === 'Saída';

  const isPix = ['Recebimento Pix Qr Code', 'RECARGA PIX CC QRCODE'].some(
    type => type === selected.transactionType,
  );

  const isDevolution = selected?.transactionType === 'Devolução de ted';

  const transactionSource = isExit ? selected?.recipient : selected?.payer;

  const userInfo = isPix ? transactionSource : selected;

  const formatDocument =
    userInfo.cpfCnpj?.length === 11
      ? maskCPF(userInfo.cpfCnpj)
      : maskCNPJ(userInfo.cpfCnpj);

  const renderUserData = (
    <Grid container className={styles.sectionContainer}>
      <Grid item xs={12} className={styles.sectionHeader}>
        <PaymentOutlined />
        <Typography variant="h3" className={styles.sectionTitle}>
          {isExit ? 'Recebedor' : 'Depositante'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" className={styles.infoBody}>
          Nome
        </Typography>
      </Grid>
      <Grid item className={styles.infoGridItems} xs={6}>
        <Typography variant="body1" className={styles.infoValue}>
          {userInfo?.name || '-'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" className={styles.infoBody}>
          Documento
        </Typography>
      </Grid>
      <Grid item className={styles.infoGridItems} xs={6}>
        <Typography variant="body1" className={styles.infoValue}>
          {formatDocument || '-'}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderBankData = (
    <Grid container className={styles.sectionContainer}>
      <Grid item xs={12} className={styles.sectionHeader}>
        <AccountBalanceOutlined />
        <Typography variant="h3" className={styles.sectionTitle}>
          Dados bancários
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" className={styles.infoBody}>
          Banco
        </Typography>
      </Grid>
      <Grid item className={styles.infoGridItems} xs={6}>
        <Typography variant="body1" className={styles.infoValue}>
          {userInfo?.bank || '-'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" className={styles.infoBody}>
          Agência
        </Typography>
      </Grid>
      <Grid item className={styles.infoGridItems} xs={6}>
        <Typography variant="body1" className={styles.infoValue}>
          {userInfo?.agency || '-'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1" className={styles.infoBody}>
          Número da conta
        </Typography>
      </Grid>
      <Grid item className={styles.infoGridItems} xs={6}>
        <Typography variant="body1" className={styles.infoValue}>
          {userInfo?.account || '-'}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderDevolutionReason = (
    <Grid container className={styles.sectionContainer}>
      <Grid item xs={12}>
        <Typography variant="h3" className={styles.sectionTitle}>
          Motivo da devolução
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" className={styles.errorReason}>
          {selected?.errorReason || '-'}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <Box className={styles.root}>
        <Grid container>
          <Grid item xs={10} className={styles.titleContainer}>
            <Typography variant="h2" className={styles.drawerTitle}>
              Detalhes do extrato
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton aria-label="Fechar" onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={styles.drawerSubtitle}>
              {`${date.format('DD [de] MMMM[,] YYYY')}`} &#8226;{' '}
              {`${date.format('HH:mm')}`}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container className={styles.infoContainer}>
          <Grid item xs={6}>
            <Typography variant="body1" className={styles.infoBody}>
              NSU
            </Typography>
          </Grid>
          <Grid item className={styles.infoGridItems} xs={6}>
            <Typography variant="body1" className={styles.infoValue}>
              {selected?.nsu || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" className={styles.infoBody}>
              Valor
            </Typography>
          </Grid>
          <Grid item className={styles.infoGridItems} xs={6}>
            <Typography variant="body1" className={styles.infoValue}>
              {currencyFormatter(Math.abs(selected.amount))}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" className={styles.infoBody}>
              Tipo de pagamento
            </Typography>
          </Grid>
          <Grid item className={styles.infoGridItems} xs={6}>
            <Typography variant="body1" className={styles.infoValue}>
              {selected.transactionType}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" className={styles.infoBody}>
              Operação
            </Typography>
          </Grid>
          <Grid item className={styles.infoGridItems} xs={6}>
            <Typography variant="body1" className={styles.infoValue}>
              {isExit ? (
                <OutflowIcon className={styles.operationIcon} />
              ) : (
                <InputMoney className={styles.operationIcon} />
              )}
              {selected.category}
            </Typography>
          </Grid>
        </Grid>
        {renderUserData}
        <Divider className={styles.divider} />
        {renderBankData}

        {isDevolution && (
          <>
            <Divider className={styles.divider} />
            {renderDevolutionReason}
          </>
        )}
      </Box>
    </Drawer>
  );
}
