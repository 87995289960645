import React, { useCallback, useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Box, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modals from '../../../../../components/Modals';
import Form from '../../../../../components/Form';
import Buttons from '../../../../../components/Buttons';
import useGeolocation from '../../../../../hooks/useGeolocation';
import {
  useConfirmAuthorizationMutation,
  usePreAuthorizePayrollMutation,
} from '../../../../../redux/store/Payroll/payroll.api';
import { useCountdown } from '../../../../../hooks/useValidationForUnformattedDate/useCountdown';
import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack';
import { handleRequestError } from '../../../../../utils/handleRequestError';
import { PayrollFileUploadActions } from '../../../../../redux/store/Payroll/PayrollFiles';

export const PayrollAuthorizationModal = NiceModal.create(
  ({ requestId = '' }) => {
    const { code: companyCode } = useSelector(
      state => state.companies.currentCompany,
    );
    const { nsuListToAuthorize } = useSelector(
      state => state.payroll.payrollFilesUpload,
    );

    const dispatch = useDispatch();

    const COUNTDOWN_TIME = 60;

    const [validationType, setValidationType] = useState('EMAIL_SENT');
    const [isCountdownStarted, setIsCountdownStarted] = useState(false);

    const { currentTime } = useCountdown(COUNTDOWN_TIME, isCountdownStarted);

    const modal = useModal();

    const { control, handleSubmit } = useForm({ defaultValues: { pin: '' } });

    const history = useHistory();
    const location = useGeolocation();

    const [
      handleConfirmAuthorization,
      { isLoading },
    ] = useConfirmAuthorizationMutation();

    const [handlePreAuthorizePayroll] = usePreAuthorizePayrollMutation();

    const handleCloseModal = () => {
      modal.remove();
    };

    const handleStartTimer = () => {
      setIsCountdownStarted(true);

      setTimeout(() => {
        setIsCountdownStarted(false);
      }, COUNTDOWN_TIME * 1000);
    };

    const onPreAuthorizePayroll = useCallback(() => {
      handleStartTimer();

      handlePreAuthorizePayroll({
        emailNotReceived: true,
        nsuList: nsuListToAuthorize?.map(nsu => Number(nsu)),
      })
        .unwrap()
        .then(data => {
          requestId.current = data?.requestId;

          setValidationType(data?.status);
        })
        .catch(errors => {
          handleRequestError(
            errors,
            'Houve um erro pontual ao pré-autorizar. Tente novamente mais tarde.',
          );
        });
    }, [nsuListToAuthorize]);

    const onConfirmAuthorization = useCallback(
      data => {
        handleConfirmAuthorization({
          requestId,
          pin: data?.pin,
          companyCode,
          nsuList: nsuListToAuthorize,
          ...location,
        })
          .unwrap()
          .then(() => {
            modal.hide();

            handleSnackbarStack().success('Pagamentos enviados com sucesso!');

            history.push('/payroll');

            dispatch(PayrollFileUploadActions.removeAllFiles());
          })
          .catch(errors => {
            handleSnackbarStack().error(
              errors?.data?.errors?.[0]?.errorDetail ||
                errors?.data?.[0]?.error ||
                'Houve um erro pontual ao pré-autorizar. Tente novamente mais tarde.',
            );
          });
      },
      [companyCode, nsuListToAuthorize, location],
    );

    useEffect(() => {
      handleStartTimer();
    }, []);

    return (
      <Modals.DefaultModal
        hasBottomBar={false}
        open={modal.visible}
        onClose={handleCloseModal}
        title="Autorizar este pagamento?"
        subtitle="Ao autorizar, o pagamento será executado na data agendada."
      >
        <Form.FormBase onSubmit={() => handleSubmit(onConfirmAuthorization)}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {validationType === 'EMAIL_SENT'
                  ? 'Digite o código enviado para seu e-mail.'
                  : 'Digite o código enviado por SMS.'}
              </Typography>
              <Controller
                control={control}
                name="pin"
                render={({ field }) => (
                  <Form.NumericField
                    precision={6}
                    label="Código de autorização"
                    helperText={
                      validationType === 'EMAIL_SENT'
                        ? 'Não recebeu o e-mail? Verifique as pastas Spam e Lixeira'
                        : 'Não recebeu o SMS? Tente realizar novamente a autorização'
                    }
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Buttons.SecondaryButton
                color="primary"
                disabled={isCountdownStarted}
                title={
                  isCountdownStarted
                    ? `Enviar código por SMS em: ${currentTime}`
                    : 'Enviar código por SMS'
                }
                onClick={onPreAuthorizePayroll}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                gap: '1rem',
              }}
            >
              <Buttons.SecondaryButton
                color="primary"
                title="Cancelar"
                onClick={handleCloseModal}
              />

              <Buttons.PrimaryButton
                type="submit"
                color="secondary"
                title="Autorizar pagamento"
                loading={isLoading}
              />
            </Box>
          </Box>
        </Form.FormBase>
      </Modals.DefaultModal>
    );
  },
);
