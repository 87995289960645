import { makeStyles, createStyles } from '@material-ui/core/styles';

const drawerWidth = 280;

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      right: 0,
      width: '100%',
      height: `calc(100% - 64px)`,
      scrollBehavior: 'smooth',
      position: 'absolute',
      justifyContent: 'flex-end',
      overflow: 'auto',
      marginTop: '64px',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: theme.zIndex.drawer + 1,
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      color: '#000000',
      opacity: 0.6,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      overflow: 'hidden',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'space-between',
      marginLeft: '22px',
    },
    drawerHeaderLogo: {
      display: 'flex',
      alignItems: 'center',
      ...theme.mixins.toolbar,
      justifyContent: 'space-between',
      position: 'fixed',
      height: 65,
      background: '#FFFFFF',
      width: drawerWidth,
      zIndex: 998,
      padding: 8,
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
    },
    infoUser: {
      marginTop: 64,
    },
    somapayIcon: {
      cursor: 'pointer',
      width: '100%',
      height: '4.5rem',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      marginBottom: 'auto',
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    menuText: {
      fontStyle: 'normal',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#1B181C',
      fontFamily: 'DM Sans',
    },
    toolbar: {
      backgroundColor: '#ffffff',
      height: 64,
      ...theme.mixins.toolbar,
    },
    selectedItemBackground: {
      backgroundColor: '#52197F',
    },
    selectedItemTextColor: {
      color: '#5C2483',
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '12px',
    },
    mobilePadding: {
      padding: 10,
      marginTop: 10,
    },
    buttonChatBot: {
      position: 'fixed',
      left: 23,
      bottom: 15,
      zIndex: 1200,
      border: '1px solid #52197F',
      borderRadius: '51px',
      padding: '6px 30px',
      textTransform: 'initial',
      fontFamily: 'DM Sans',
      color: '#52197F',
      fontSize: 15,
    },
    backgroundChat: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      background: '#ffffff',
      height: '70px',
      width: '279px',
      zIndex: 1200,
    },
    balanceAreaStyle: {
      width: '80%',
      padding: '10px 0px',
      display: 'flex',
      justifyContent: 'center',
      margin: '0px auto',
      background: '#FAFAFA',
      border: '1px solid #FAFAFA',
      borderRadius: '6px',
      height: '47px',
    },
    buttonBalances: {
      background: '#E5E5E5',
      borderRadius: '2px',
      width: '60%',
      border: 'none',
    },
    balanceIconStyle: {
      marginLeft: 8,
      color: '#808080',
      cursor: 'pointer',
    },
    moneyIconStyle: {
      marginRight: 8,
      color: '#808080',
    },
    buttonName: {
      fontFamily: 'DM Sans',
      width: '132.7px',
      textAlign: 'center',
      fontSize: '16px',
      position: 'relative',
      bottom: '2px',
    },
    dividerMenu: {
      marginTop: '12px',
      marginBottom: '14px',
    },
    logoutButton: {
      color: '#999999',
      textTransform: 'initial',
      borderRadius: '51px',
      marginLeft: '10px',
      marginRight: '15px',
      padding: '4px 22px',
      height: '35px',
    },

    accordionActions: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignContent: 'flex-start',
      backgroundColor: '#f6f3f9',
      borderLeft: '1px solid rgba(82, 25, 127, 0.02)',
      borderRight: '1px solid rgba(82, 25, 127, 0.02)',
      boxSizing: 'border-box',
      borderRadius: '2px',
      textTransform: 'initial',
      padding: '4px 0px',
      margin: '0px',
    },
    buttonLogout: {
      marginTop: '-8px',
      marginLeft: '10px',
      paddingLeft: '5px',
    },
  }),
);

export default useStyles;
