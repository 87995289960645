import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import { MenuAccordion } from './Components/MenuAccordion';
import { MenuItem } from './Components/MenuItem';
import { MenuAccordionButton } from './Components/MenuAccordionButton';

import { useMainMenuOptions } from './helpers/useMainMenuOptions';
import { itemsUrls } from './helpers/itemsUrls';
import { isSubsidiaryCompanie } from '../../../../utils/isSubsidiaryCompanie';

import styles from './styles.module.css';

export function MainMenu({
  payrollProduct,
  handleReleaseAnticipationScreens,
  onConfirmExitButtonClicked,
  consigned,
  beevaleBenefits,
}) {
  const { situationPointPlan } = useSelector(
    state => state.situationPointPlan.data,
  );

  const { accountSelected } = useSelector(state => state.account);

  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));
  const isSubsidiary = isSubsidiaryCompanie();

  const [accordionExpanded, setAccordionExpanded] = useState('');
  const [mainMenuOptionSelected, setMainMenuOptionSelected] = useState(
    'generalOverview',
  );

  const { mainMenuOptions } = useMainMenuOptions(
    ACCESS_PROFILES,
    payrollProduct,
    isSubsidiary,
    situationPointPlan,
    handleReleaseAnticipationScreens,
    onConfirmExitButtonClicked,
    consigned,
    beevaleBenefits,
    accountSelected,
  );

  const financesAccordionExpanded =
    accordionExpanded === 'finances' ||
    [
      'cashIn',
      'payment',
      'bankExtract',
      'manageAccounts',
      'pix',
      'valuesQuery',
      'manageRequests',
    ].includes(mainMenuOptionSelected);

  const employeesAccordionExpanded =
    accordionExpanded === 'employees' ||
    [
      'manageEmployees',
      'registerFiles',
      'paycheck',
      'valuesAnticipation',
      'limitQuery',
      'reprovedOnboardings',
    ].includes(mainMenuOptionSelected);

  const companyAccordionExpanded =
    accordionExpanded === 'company' ||
    ['costCenterAndSector', 'myPlanSomapay', 'somapayAcademy'].includes(
      mainMenuOptionSelected,
    );

  const onlinePointAccordionExpanded =
    accordionExpanded === 'onlinePoint' ||
    mainMenuOptionSelected === 'collaborators' ||
    mainMenuOptionSelected === 'myPoint' ||
    mainMenuOptionSelected === 'invoices';

  const consignedAccordionExpanded =
    accordionExpanded === 'consigned' ||
    mainMenuOptionSelected === 'consignedProposes';
  const beevaleBenefitsAccordionExpanded =
    accordionExpanded === 'beevaleBenefits' ||
    mainMenuOptionSelected === 'beevaleBenefitsEmployee';

  const insuranceAccordionExpanded =
    accordionExpanded === 'insurance' || mainMenuOptionSelected === 'insurance';

  const accordionsExpandedConstEnum = {
    finances: financesAccordionExpanded,
    employees: employeesAccordionExpanded,
    company: companyAccordionExpanded,
    onlinePoint: onlinePointAccordionExpanded,
    consigned: consignedAccordionExpanded,
    insurance: insuranceAccordionExpanded,
    beevaleBenefits: beevaleBenefitsAccordionExpanded,
  };

  const handleChange = panel => {
    setAccordionExpanded(accordionExpanded !== panel ? panel : '');
  };

  useEffect(() => {
    setMainMenuOptionSelected(
      itemsUrls?.find(item => window.location.pathname === item.pathname)
        ?.itemName,
    );
  }, [window.location.pathname]);

  return (
    <Box className={styles.mainMenuContainer}>
      {mainMenuOptions?.map(menuOption =>
        menuOption?.isAccordion && menuOption.isVisible ? (
          <MenuAccordion
            key={menuOption?.panelName}
            accordionId={menuOption?.panelName}
            summaryIcon={
              accordionsExpandedConstEnum[menuOption?.panelName]
                ? menuOption?.panelActiveIcon
                : menuOption?.panelIdleIcon
            }
            summaryText={menuOption?.panelTitle}
            isNew={menuOption?.isNew}
            expanded={accordionsExpandedConstEnum[menuOption?.panelName]}
            handleExpanded={handleChange}
          >
            {menuOption?.accordionItems?.map(
              item =>
                item?.isVisible && (
                  <MenuAccordionButton
                    key={item?.itemName}
                    option={item?.itemName}
                    text={item?.itemTitle}
                    isNew={item?.isNew}
                    selected={mainMenuOptionSelected}
                    onClick={() => {
                      if (item?.isToggle) {
                        setMainMenuOptionSelected(item?.itemName);
                      }
                      item.action();
                    }}
                    disabled={item?.isDisabled}
                  />
                ),
            )}
          </MenuAccordion>
        ) : (
          menuOption?.isVisible && (
            <MenuItem
              key={menuOption?.itemName}
              option={menuOption?.itemName}
              icon={
                mainMenuOptionSelected === menuOption?.itemName &&
                menuOption.isToggle
                  ? menuOption?.panelActiveIcon
                  : menuOption?.panelIdleIcon
              }
              text={menuOption?.panelTitle}
              isNew={menuOption?.isNew}
              selected={mainMenuOptionSelected}
              onClick={() => {
                if (menuOption?.isToggle) {
                  handleChange('');
                  setMainMenuOptionSelected(menuOption?.itemName);
                }
                menuOption.action();
              }}
              disabled={menuOption?.isDisabled}
            />
          )
        ),
      )}
    </Box>
  );
}
