import React from 'react';

import { Box } from '@material-ui/core';

import Infos from './Infos';
import Form from './Form';

import styles from './styles.module.css';

export default function Card({
  pixValue,
  notes,
  handleChangePixValue,
  handleChangeNotes,
  INPUT_VALIDATE,
  loading,
}) {
  return (
    <Box mt={4} className={styles.card}>
      <Infos />
      <Form
        pixValue={pixValue}
        notes={notes}
        handleChangePixValue={handleChangePixValue}
        handleChangeNotes={handleChangeNotes}
        INPUT_VALIDATE={INPUT_VALIDATE}
        loading={loading}
      />
    </Box>
  );
}
