import React from 'react';
import Lottie from 'react-lottie';

import { Grid, Typography } from '@material-ui/core';

import checkAnimation from '../../../../lotties/check.json';

import styles from './styles.module.css';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: checkAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function Header() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Lottie options={animationOptions} width={130} />

      <Typography className={styles.biggerText}>
        QR Code gerado com sucesso
      </Typography>

      <Typography className={styles.mediumText}>
        Confira as informações e use o QR Code para pagar.
      </Typography>
    </Grid>
  );
}
