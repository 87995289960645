import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import Buttons from '../../../../components/Buttons';

import { api } from '../../../../services/api';
import currencyFormatter from '../../../../utils/currencyFormatter';
import convertBytesToBlobDowload from '../../../../utils/convertBytesToBlobDowload';
import convertBytesToBlobDownloadZip from '../../../../utils/convertBytesToBlobDownloadZip';
import { ReactComponent as PdfIcon } from '../../../../assets/pdfIcon.svg';
import { handleSnackbarStack } from '../../../../utils/handleSnackbarStack';

export default function useTableConfig(
  setPaymentType,
  setDepositAccountEncodedKey,
) {
  const { error } = handleSnackbarStack();

  const { accountSelected } = useSelector(state => state.account);

  const [isFetching, setFetching] = useState(false);

  const [data, setData] = useState({});
  const [employees, setEmployees] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: parseInt(sessionStorage.getItem('TablePageSize')) || 5,
  });
  const [currentCpfDownloading, setCurrentCpfDownloading] = useState('');

  const { nsu } = JSON.parse(sessionStorage.getItem('payrollData'));
  const authToken = sessionStorage.getItem('@ContaSoma-Token');
  const currentCompanyCode = sessionStorage.getItem('currentCompanyCode');

  const handleDataRequest = useCallback(
    (page, size) => {
      setFetching(true);
      setPagination({ page, size });

      const requestOptions = {
        headers: {
          Authorization: authToken,
        },
        params: {
          page,
          size,
        },
      };

      api
        .get(`/employeesPayments/${nsu}`, requestOptions)
        .then(({ data }) => {
          setData(data);
          setEmployees(data.details);
          setDepositAccountEncodedKey(data.depositAccount);
          setPaymentType(data.details?.[0]?.type);
        })
        .catch(err => error(err))
        .finally(() => setFetching(false));
    },
    [pagination, nsu, authToken],
  );

  const disableSelection = row =>
    !['Paga Imediata', 'Paga'].includes(row?.status);

  const handleDownload = row => {
    setCurrentCpfDownloading(row?.cpf);

    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
      },
      responseType: 'blob',
    };

    api
      .get(
        `/employeesPayments/receiptPayment/${currentCompanyCode}/${nsu}?cpf=${row.cpf}`,
        requestOptions,
      )
      .then(({ data }) => {
        convertBytesToBlobDowload(data, `comprovante_pagamento_${nsu}`);
      })
      .catch(err => error(err))
      .finally(() => setCurrentCpfDownloading(''));
  };

  const handleDownloadAll = () => {
    api
      .get(
        `/employeesPayments/paymentReport/location/${currentCompanyCode}/${nsu}`,
        {
          responseType: 'blob',
        },
      )
      .then(({ data }) => {
        convertBytesToBlobDownloadZip(data, `comprovante_pagamento_${nsu}`);
      })
      .catch(err => error(err));
  };

  useEffect(() => {
    handleDataRequest();
  }, []);

  return {
    data,
    handleDataRequest,
    employees,
    setEmployees,
    pagination,
    length: data.totalSize,
    isLoading: isFetching,
    disableSelection,
    handleDownload,
    handleDownloadAll,
    config: [
      {
        key: 'status',
        type: 'field',
        label: 'Situação',
      },
      {
        key: 'cpf',
        type: 'field',
        label: 'CPF',
      },
      {
        key: 'name',
        type: 'field',
        label: 'Nome',
      },
      {
        key: 'locationName',
        type: 'field',
        label: 'Centro de custo',
        render: ({ locationName }) => locationName || 'Não cadastrado',
      },
      {
        key: 'consumesFranchise',
        type: 'field',
        label: 'Consome franquia?',
        render: ({ consumesFranchise }) => (consumesFranchise ? 'Sim' : 'Não'),
      },
      {
        key: 'feeAmount',
        type: 'field',
        label: 'Tarifa',
        render: row => currencyFormatter(row?.feeAmount || 0),
      },
      {
        key: 'amount',
        type: 'field',
        label: 'Valor',
        render: row => currencyFormatter(row.amount || 0),
      },
      {
        key: 'receipt',
        type: 'action',
        icon: <PdfIcon color="primary" />,
        label: 'Baixar comprovante',
        isLoading: row => row?.cpf === currentCpfDownloading,
        disabled: row => disableSelection(row),
        onClick: row => handleDownload(row),
      },
    ],
  };
}
