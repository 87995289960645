import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useChipStatus } from './Hooks/useChipStatus';

export function ChipStatus({
  status = 'default',
  icon = () => {},
  label = '-',
  size = 'medium',
  variant = 'outlined',
  classes = {},
  chipStyle = {},
  ...otherProps
}) {
  const { chipColor, fontColor } = useChipStatus(status);
  return (
    <Chip
      icon={icon}
      label={label}
      size={size}
      variant={variant}
      classes={classes}
      style={{
        backgroundColor:
          variant === 'string'
            ? 'none'
            : variant === 'outlined'
            ? chipColor
            : fontColor,
        color:
          variant === 'string' || variant === 'outlined'
            ? fontColor
            : chipColor,
        width: 'fit-content',
        border:
          variant === 'string' || variant === 'filled'
            ? 'none'
            : `1px solid ${fontColor}`,
        fontSize: '0.875rem',
        fontWeight: '500',
        ...chipStyle,
      }}
      {...otherProps}
    />
  );
}
