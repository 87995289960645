import React from 'react';
import { Box, Typography } from '@material-ui/core';

import Title from '../Title';

export function Header({ title, subtitle = '', buttons }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={3}
    >
      <Box>
        <Title>{title}</Title>
        {subtitle && (
          <Typography
            style={{ fontSize: '1rem', fontWeight: 500, color: '#666666' }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems={{ xs: 'stretch' }} sx={{ gap: '1rem' }}>
        {buttons}
      </Box>
    </Box>
  );
}
