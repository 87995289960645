import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';

import { PrivateRoute, SignOutRoute } from './utils/RequireAuth';

import SideMenu from './components/SideMenu';
import BlockedAccessModal from './components/PrimaryModal';

import Login from './pages/Login';
import Welcome from './pages/WelcomeCarousel';
import Home from './pages/Home';

import {
  CreatePassword,
  EmailAndCpf,
  RequestHelp,
  SuccessMessage,
  ValidateToken,
} from './pages/ResetPassword';

import CostAndSectorCenter from './pages/CostAndSectorCenter';

import MyPlan from './pages/MyPlan';

import {
  EmployeesBatchRegistrationEmployeeList,
  EmployeesBatchRegistrationEnd,
  EmployeesBatchRegistrationExcelForm,
  EmployeesBatchRegistrationMenu,
  EmployeesBatchRegistrationStart,
  EmployeesBatchRegistrationSuccess,
} from './pages/EmployeesBatchRegistration';

import {
  ConsultPayroll,
  EmployeePaymentsQrCodeInformation,
  ImportPayroll,
  IncludeCollaborators,
  PayrollDetails,
  PayrollMenu,
  PayrollDataReview,
  PayrollInfo,
  PayrollList,
  PayrollSuccessfulScheduling,
  RegisterERPImportFile,
  ManualPayroll,
  RegisterERPEmployeeList,
  RegisterERPSuccess,
  SetEmployeesValues,
  PayrollRegenerate,
} from './pages/Payroll';

import {
  ConsultCashIn,
  GeneratedBillet,
  NewBillingDeposit,
  InformTransferDeposit,
  Ted,
  TedSetData,
  PixSetValue,
  PixQRCode,
} from './pages/CashIn';

import { AnticipationValues } from './pages/AnticipationValues';

import {
  ConsultLimit,
  FileTypeChoice,
  SendFile,
  HandleRequiredItems,
} from './pages/AnticipationValues/ConsultGrantedLimit';

import {
  ConsolidatedValues,
  RequestingEmployees,
  ConsolidatedValuesDetails,
} from './pages/AnticipationValues/ConsultConsolidatedValues';

import {
  InsuranceSingleScreen,
  InsuranceManagement,
  InsuranceRegister,
  ViewDetails,
  Details,
  InsurenceBatchRegistrationMenu,
  InsuranceFileImport,
  InsuranceFileReview,
} from './pages/Insurance';

import {
  PaycheckMenu,
  PaycheckImport,
  PaycheckDataReview,
  PaycheckSuccess,
  PaycheckManagement,
  PaycheckDetails,
} from './pages/Paycheck';

import {
  EmployeesCpfValidate,
  EmployeesList,
  EmployeesOnboardingReproved,
  EmployeesRegister,
  EmployeesSuccess,
  EmployeesRegisterFiles,
} from './pages/Employees';

import BankAccounts from './pages/BankAccounts';

import Extract from './pages/Extract';

import AuthorizePaymentModal from './pages/Payroll/SuccessfulScheduling/UI/AuthorizePaymentModal';

import SomapayAcademy from './pages/SomapayAcademy';
import AccessSettings from './pages/AccessSettings';

import {
  CreatePasswordPassword,
  UsersManagement,
  UserRegister,
} from './pages/ManageUsers';

import OnlinePointEmployees from './pages/OnlinePointEmployees';
import OnlinePointPlan from './pages/OnlinePointPlan';
import OnlinePointCharges from './pages/OnlinePointCharges';

import { hasAuthorization, profiles } from './utils/hasAuthorization';
import { isSubsidiaryCompanie } from './utils/isSubsidiaryCompanie';
import OnlinePointChargesDetailed from './pages/OnlinePointChargesDetailed';
import ConsultCosignedToApprove from './pages/Consigned/Proposes';
import ConsignedMarginsList from './pages/Consigned/Margins/List';
import ConsignedMarginsBatchInsertion from './pages/Consigned/Margins/BatchInsertion/Create';
import ConsignedMarginsBatchConfirm from './pages/Consigned/Margins/BatchInsertion/Confirm';
import ConsignedMarginsBatchResult from './pages/Consigned/Margins/BatchInsertion/Result';
import ManageCashOutRequests from './pages/CashOut/ManageRequests';
import CashOutPix from './pages/CashOut/Pix';
import CashOutBill from './pages/CashOut/Bill';
import CashOutTransfer from './pages/CashOut/Transfer';
import AcademyPage from './pages/Academy';
import HelpFlowPage from './pages/Academy/components/HelpFlowPage';
import ResetPasswordSMS from './pages/ResetPassword/SMS';
import BeevaleBenefitsEmployees from './pages/BeevaleBenefits/BeevaleBenefitsEmployees';

import {
  BeevaleBenefitsRecharges,
  CreateRecharge,
  RechargeDetails,
} from './pages/BeevaleBenefits/Recharges';
import ManageUsersPage from './pages/Employees/ManageUsers';
import NewUserPage from './pages/Employees/ManageUsers/components/NewUserPage';
import MultipleFilesUploadScreen from './pages/Payroll/MultipleFilesUpload';
import PayrollFilesConfirmScreen from './pages/Payroll/MultipleFilesUploadReview';

const DOWNTIME_FOR_LOGOUT = process.env.REACT_APP_DOWNTIME_FOR_LOGOUT;

const UNAUTHENTICATED_ROUTES = ['reset-password', 'login'];

const currentPathName = () => window.location.pathname;

const isAuthenticatedRouter = (unauthenticatedRoutes, pathname) =>
  !unauthenticatedRoutes.some(route => pathname.includes(route));

const Routes = () => {
  const history = useHistory();

  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));
  const notAuthorizeRoute = sessionStorage.getItem('notAuthorizeRoute');

  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [openBlockedAccessModal, setOpenBlockedAccessModal] = useState(
    !!notAuthorizeRoute,
  );

  const isSubsidiary = isSubsidiaryCompanie();

  useEffect(() => {
    if (
      history.location.pathname.substring(0, 8) === '/cash-in' &&
      (!hasAuthorization(ACCESS_PROFILES, [
        profiles.ADMIN,
        profiles.FINANCIAL,
      ]) ||
        isSubsidiary)
    ) {
      handleNotAuthorize();
    }

    if (
      history.location.pathname.substring(0, 8) === '/payroll' &&
      !hasAuthorization(ACCESS_PROFILES, [
        profiles.ADMIN,
        profiles.PERSONAL_DEPARTMENT,
        profiles.FINANCIAL,
      ])
    ) {
      handleNotAuthorize();
    }

    if (
      history.location.pathname === '/extract' &&
      !hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN, profiles.FINANCIAL])
    ) {
      handleNotAuthorize();
    }

    if (
      history.location.pathname === '/manage-registered-accounts' &&
      !hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN, profiles.FINANCIAL])
    ) {
      handleNotAuthorize();
    }

    if (
      (history.location.pathname.substring(0, 10) === '/employees' ||
        history.location.pathname.substring(0, 9) === '/paycheck') &&
      !hasAuthorization(ACCESS_PROFILES, [
        profiles.ADMIN,
        profiles.PERSONAL_DEPARTMENT,
      ])
    ) {
      handleNotAuthorize();
    }

    if (
      history.location.pathname === '/cost-and-sector-center' &&
      !hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN, profiles.FINANCIAL])
    ) {
      handleNotAuthorize();
    }

    if (
      history.location.pathname.substring(0, 8) === '/my-plan' &&
      !hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN, profiles.FINANCIAL])
    ) {
      handleNotAuthorize();
    }

    if (
      history.location.pathname.substring(0, 10) === '/insurance' &&
      !hasAuthorization(ACCESS_PROFILES, [profiles.PERSONAL_DEPARTMENT])
    ) {
      handleNotAuthorize();
    }

    if (
      history.location.pathname === '/online-point-employees' &&
      !hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN, profiles.FINANCIAL])
    ) {
      handleNotAuthorize();
    }

    if (
      history.location.pathname === '/online-point-plan' &&
      !hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN, profiles.FINANCIAL])
    ) {
      handleNotAuthorize();
    }

    if (
      history.location.pathname === '/online-point-charges' &&
      !hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN, profiles.FINANCIAL])
    ) {
      handleNotAuthorize();
    }

    if (
      history.location.pathname === '/online-point-charges/:id' &&
      !hasAuthorization(ACCESS_PROFILES, [profiles.ADMIN, profiles.FINANCIAL])
    ) {
      handleNotAuthorize();
    }

    if (
      history.location.pathname === '/consigned/proposes' &&
      !hasAuthorization(ACCESS_PROFILES, [
        profiles.ADMIN,
        profiles.PERSONAL_DEPARTMENT,
      ])
    ) {
      handleNotAuthorize();
    }

    if (history.location.pathname === '/reset-password/create-password') {
      history.push('/reset-password/email-and-cpf');
    }

    if (history.location.pathname === '/reset-password/validate-token') {
      history.push('/reset-password/email-and-cpf');
    }

    if (history.location.pathname === '/reset-password/sms') {
      history.push('/reset-password/email-and-cpf');
    }

    if (
      history.location.pathname.substring(0, 13) === '/manage-users' &&
      !hasAuthorization(ACCESS_PROFILES, [])
    ) {
      handleNotAuthorize();
    }
  }, []);

  const handleNotAuthorize = () => {
    history.goBack();
    sessionStorage.setItem('notAuthorizeRoute', true);
  };

  const hideAlert = () => {
    setShowAlert(false);
    setAlertMessage('');
  };

  const handleIdle = () => {
    if (isAuthenticatedRouter(UNAUTHENTICATED_ROUTES, currentPathName())) {
      setShowAlert(true);
      setAlertMessage(
        'Sua sessão expirou por inatividade. Para sua segurança, faça login novamente.',
      );
      history.push('/logout');
    }
  };

  return (
    <div>
      <BlockedAccessModal
        open={openBlockedAccessModal}
        title="Acesso bloqueado."
        text="Seu perfil de acesso não tem autorização para acessar essa
        funcionalidade da plataforma."
        onConfirmClicked={() => {
          sessionStorage.removeItem('notAuthorizeRoute');
          setOpenBlockedAccessModal(false);
        }}
        confirmButtonText="Entendi"
      />

      <IdleTimer
        element={document}
        onIdle={handleIdle}
        debounce={250}
        timeout={DOWNTIME_FOR_LOGOUT}
      />
      <Switch>
        <Route exact path="/login">
          <Login
            hideAlert={hideAlert}
            showAlert={showAlert}
            alertMessage={alertMessage}
          />
        </Route>

        <Route
          exact
          path="/reset-password/email-and-cpf"
          component={EmailAndCpf}
        />

        <Route exact path="/reset-password/sms" component={ResetPasswordSMS} />

        <Route
          exact
          path="/reset-password/validate-token"
          component={ValidateToken}
        />
        <Route
          exact
          path="/reset-password/request-help"
          component={RequestHelp}
        />
        <Route
          exact
          path="/reset-password/create-password"
          component={CreatePassword}
        />
        <Route
          exact
          path="/reset-password/success-message"
          component={SuccessMessage}
        />

        <Route
          exact
          path="/manage-users/new-password/:id"
          component={CreatePasswordPassword}
        />

        <Route
          exact
          path="/companies/employeesPayments/QRCode/information/:nsuAndLineIdEncrypted"
          component={EmployeePaymentsQrCodeInformation}
        />

        <SignOutRoute exact path="/logout" />

        <PrivateRoute exact path="/welcome" component={Welcome} />

        <SideMenu>
          <Switch>
            <PrivateRoute exact path="/" component={Home} />

            <PrivateRoute exact path="/employees" component={EmployeesList} />
            {/*
            <PrivateRoute
              exact
              path="/employees/manage-users"
              component={ManageUsersPage}
            />

            <PrivateRoute
              exact
              path="/employees/manage-users/new"
              component={NewUserPage}
            /> */}

            <PrivateRoute
              exact
              path="/employees/register-single"
              component={EmployeesCpfValidate}
            />
            <PrivateRoute
              exact
              path="/employees/authorize-payment-modal"
              component={AuthorizePaymentModal}
            />
            <PrivateRoute
              exact
              path="/employees/reproved-onboarding"
              component={EmployeesOnboardingReproved.List}
            />
            <PrivateRoute
              exact
              path="/employees/register-single/form/success"
              component={EmployeesSuccess}
            />
            <PrivateRoute
              exact
              path="/employees/register-batch"
              component={EmployeesBatchRegistrationStart}
            />
            <PrivateRoute
              exact
              path="/employees/register-batch/menu"
              component={EmployeesBatchRegistrationMenu}
            />
            <PrivateRoute
              exact
              path="/employees/register-batch/menu/select-company/excel-form"
              component={EmployeesBatchRegistrationExcelForm}
            />
            <PrivateRoute
              exact
              path="/employees/register-batch/menu/select-company/excel-form/employee-list"
              component={EmployeesBatchRegistrationEmployeeList}
            />
            <PrivateRoute
              exact
              path="/employees/register-batch/menu/select-company/excel-form/employee-list/end"
              component={EmployeesBatchRegistrationEnd}
            />
            <PrivateRoute
              exact
              path="/employees/register-batch/success"
              component={EmployeesBatchRegistrationSuccess}
            />
            <PrivateRoute
              exact
              path="/employees/register-files"
              component={EmployeesRegisterFiles.List}
            />
            <PrivateRoute
              exact
              path="/employees/register-files/details"
              component={EmployeesRegisterFiles.Details}
            />
            <PrivateRoute
              exact
              path="/employees/:action"
              component={EmployeesRegister}
            />

            <PrivateRoute exact path="/payroll" component={ConsultPayroll} />
            <PrivateRoute
              exact
              path="/payroll/details"
              component={PayrollDetails}
            />
            <PrivateRoute exact path="/payroll/menu" component={PayrollMenu} />
            <PrivateRoute
              exact
              path="/payroll/menu/info-payroll"
              component={PayrollInfo}
            />
            <PrivateRoute
              exact
              path="/payroll/menu/info-payroll/register-batch"
              component={ImportPayroll}
            />
            <PrivateRoute
              exact
              path="/payroll/menu/info-payroll/register-batch/list-error"
              component={PayrollList}
            />
            <PrivateRoute
              exact
              path="/payroll/menu/info-payroll/register-batch/data-review"
              component={PayrollDataReview}
            />
            <PrivateRoute
              exact
              path="/payroll/success"
              component={PayrollSuccessfulScheduling}
            />
            <PrivateRoute
              exact
              path="/payroll/manual-payroll"
              component={ManualPayroll}
            />
            <PrivateRoute
              exact
              path="/payroll/manual-payroll/include-collaborators"
              component={IncludeCollaborators}
            />
            <PrivateRoute
              exact
              path="/payroll/manual-payroll/include-collaborators/set-employees-values"
              component={SetEmployeesValues}
            />
            <PrivateRoute
              exact
              path="/payroll/manual-payroll/payroll-regenerate"
              component={PayrollRegenerate}
            />
            <PrivateRoute
              exact
              path="/payroll/register-erp-file"
              component={RegisterERPImportFile}
            />
            <PrivateRoute
              exact
              path="/payroll/register-erp-employee-list"
              component={RegisterERPEmployeeList}
            />
            <PrivateRoute
              exact
              path="/payroll/register-erp-success"
              component={RegisterERPSuccess}
            />

            <PrivateRoute
              exact
              path="/payroll/file-batch/upload"
              component={MultipleFilesUploadScreen}
            />

            <PrivateRoute
              exact
              path="/payroll/file-batch/confirm"
              component={PayrollFilesConfirmScreen}
            />

            <PrivateRoute exact path="/cash-in" component={ConsultCashIn} />
            <PrivateRoute
              exact
              path="/cash-in/new/billing"
              component={NewBillingDeposit}
            />
            <PrivateRoute
              exact
              path="/cash-in/new/billing/genereted-billet"
              component={GeneratedBillet}
            />
            <PrivateRoute
              exact
              path="/cash-in/inform-transfer-Deposit"
              component={InformTransferDeposit}
            />
            <PrivateRoute
              exact
              path="/cash-in/new/billing/ted-set-data"
              component={TedSetData}
            />
            <PrivateRoute exact path="/cash-in/teds/:id" component={Ted} />

            <PrivateRoute exact path="/cash-in/pix" component={PixSetValue} />
            <PrivateRoute
              exact
              path="/cash-in/pix/qrcode"
              component={PixQRCode}
            />

            <PrivateRoute
              exact
              path="/cash-out/manage"
              component={ManageCashOutRequests}
            />

            <PrivateRoute exact path="/cash-out/pix" component={CashOutPix} />

            <PrivateRoute exact path="/cash-out/bill" component={CashOutBill} />

            <PrivateRoute
              exact
              path="/cash-out/transfer"
              component={CashOutTransfer}
            />

            <PrivateRoute
              exact
              path="/anticipation-values"
              component={AnticipationValues}
            />
            <PrivateRoute exact path="/limit-query" component={ConsultLimit} />
            <PrivateRoute
              exact
              path="/limit-query/menu"
              component={FileTypeChoice}
            />
            <PrivateRoute
              exact
              path="/limit-query/menu/info-send-file"
              component={SendFile}
            />
            <PrivateRoute
              exact
              path="/limit-query/menu/info-send-file/list-error"
              component={HandleRequiredItems}
            />
            <PrivateRoute
              exact
              path="/consolidated-values"
              component={ConsolidatedValues}
            />
            <PrivateRoute
              exact
              path="/requesting-employees"
              component={RequestingEmployees}
            />
            <PrivateRoute
              exact
              path="/requesting-employees/:id"
              component={ConsolidatedValuesDetails}
            />
            <PrivateRoute
              exact
              path="/cost-and-sector-center"
              component={CostAndSectorCenter}
            />

            <PrivateRoute
              exact
              path="/manage-users"
              component={UsersManagement}
            />
            <PrivateRoute
              exact
              path="/manage-users/register"
              component={UserRegister}
            />
            <PrivateRoute
              exact
              path="/manage-users/:id"
              component={UserRegister}
            />

            <PrivateRoute exact path="/my-plan" component={MyPlan} />

            <PrivateRoute
              exact
              path="/somapay-academy"
              component={SomapayAcademy}
            />

            <PrivateRoute exact path="/help-center" component={AcademyPage} />
            <PrivateRoute
              exact
              path="/help-center/:id"
              component={HelpFlowPage}
            />

            <PrivateRoute
              exact
              path="/access-settings"
              component={AccessSettings}
            />

            <PrivateRoute
              exact
              path="/manage-registered-accounts"
              component={BankAccounts}
            />

            <PrivateRoute exact path="/extract" component={Extract} />

            <PrivateRoute
              exact
              path="/insurance-management"
              component={InsuranceManagement}
            />
            <PrivateRoute
              exact
              path="/insurance-management/insurance-single"
              component={InsuranceSingleScreen}
            />
            <PrivateRoute
              exact
              path="/insurance-management/insurance-single/register"
              component={InsuranceRegister}
            />
            <PrivateRoute
              exact
              path="/insurance-management/view-details"
              component={ViewDetails}
            />
            <PrivateRoute
              exact
              path="/insurance-management/insurance-details/:cpf"
              component={Details}
            />

            <PrivateRoute
              exact
              path="/insurance-management/batch-registration/menu"
              component={InsurenceBatchRegistrationMenu}
            />
            <PrivateRoute
              exact
              path="/insurance-management/batch-registration/menu/validate"
              component={InsuranceFileImport}
            />
            <PrivateRoute
              exact
              path="/insurance-management/batch-registration/menu/validate/review/:fileId"
              component={InsuranceFileReview}
            />

            <PrivateRoute
              exact
              path="/paycheck"
              component={PaycheckManagement}
            />
            <PrivateRoute
              exact
              path="/paycheck/details/:id"
              component={PaycheckDetails}
            />
            <PrivateRoute
              exact
              path="/paycheck/paycheck-menu"
              component={PaycheckMenu}
            />
            <PrivateRoute
              exact
              path="/paycheck/paycheck-import"
              component={PaycheckImport}
            />
            <PrivateRoute
              exact
              path="/paycheck/paycheck-data-review/:fileId"
              component={PaycheckDataReview}
            />
            <PrivateRoute
              exact
              path="/paycheck/paycheck-success/:fileId"
              component={PaycheckSuccess}
            />

            <PrivateRoute
              exact
              path="/online-point-employees"
              component={OnlinePointEmployees}
            />
            <PrivateRoute
              exact
              path="/online-point-plan"
              component={OnlinePointPlan}
            />
            <PrivateRoute
              exact
              path="/online-point-charges"
              component={OnlinePointCharges}
            />
            <PrivateRoute
              exact
              path="/online-point-charges/:id"
              component={OnlinePointChargesDetailed}
            />
            <PrivateRoute
              exact
              path="/consigned/proposes"
              component={ConsultCosignedToApprove}
            />
            <PrivateRoute
              exact
              path="/consigned/margins"
              component={ConsignedMarginsList}
            />
            <PrivateRoute
              exact
              path="/beevaleBenefits/employee"
              component={BeevaleBenefitsEmployees}
            />
            <PrivateRoute
              exact
              path="/beevaleBenefits/recharge"
              component={BeevaleBenefitsRecharges}
            />
            <PrivateRoute
              exact
              path="/beevaleBenefits/recharge/details"
              component={RechargeDetails}
            />
            <PrivateRoute
              exact
              path="/beevaleBenefits/create/recharge"
              component={CreateRecharge}
            />
            <PrivateRoute
              exact
              path="/consigned/margins/batch"
              component={ConsignedMarginsBatchInsertion}
            />
            <PrivateRoute
              exact
              path="/consigned/margins/batch/:processId/confirm"
              component={ConsignedMarginsBatchConfirm}
            />
            <PrivateRoute
              exact
              path="/consigned/margins/batch/:processId/result"
              component={ConsignedMarginsBatchResult}
            />

            <Redirect to="/" />
          </Switch>
        </SideMenu>
      </Switch>
    </div>
  );
};
export default Routes;
