import React from 'react';

import {
  Box,
  Grid,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core';

import brazilianCurrencyFormatter2 from '../../../../utils/brazilianCurrencyFormatter2';

import styles from './styles.module.css';

export default function Form({
  pixValue,
  notes,
  handleChangePixValue,
  handleChangeNotes,
  INPUT_VALIDATE,
  loading,
}) {
  return (
    <>
      <Box mb={3} mt={5}>
        <Typography className={styles.biggerText}>
          Insira as informações abaixo para gerar o seu QR Code:
        </Typography>

        <Box mt={0.5} />

        <Typography className={styles.defaultText}>
          Você pode transferir um valor entre <span>R$20,00</span> e{' '}
          <span>R$9.000.000,00</span>.
        </Typography>
      </Box>

      <Grid container wrap="wrap">
        <Grid item className={styles.grid} xs={12} md={3} lg={3}>
          <TextField
            variant="outlined"
            placeholder="Valor do Pix*"
            value={brazilianCurrencyFormatter2(pixValue)}
            onChange={handleChangePixValue}
            InputProps={{
              maxLength: 15,
              minLength: 8,
            }}
            disabled={loading}
            className={styles.valueBilletInput}
            fullWidth
            label="Valor do Pix*"
            helperText={
              <FormHelperText
                className={
                  INPUT_VALIDATE ? styles.redHelperText : styles.helperText
                }
              >
                {INPUT_VALIDATE
                  ? 'Digite um valor entre R$ 20,00 e R$ 9.000.000,00'
                  : '*Obrigatório'}
              </FormHelperText>
            }
          />
        </Grid>

        <Grid item xs={12} md={9} lg={9}>
          <TextField
            variant="outlined"
            placeholder="Observações"
            label="Observações"
            fullWidth
            value={notes}
            onChange={handleChangeNotes}
            disabled={loading}
          />
        </Grid>
      </Grid>
    </>
  );
}
