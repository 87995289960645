import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';

import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import moment from 'moment';
import { WarningRounded } from '@material-ui/icons';
import InfoGrid from '../../../components/InfoGrid';
import ProgressToolBar from '../../../components/ProgressToolBar';
import AlreadyUploadModal from '../../../components/PrimaryModal';
import ChargeAlertModal from '../../../components/PrimaryModal';
import ErrorModal from '../../../components/PrimaryModal';

import ChangeSeetDataModal from '../IncludeCollaborators/UI/ChangeSeetDataModal';
import ProgressItem from './UI/ProgressItem';
import Table from './UI/Table';
import TablePaginationActions from './UI/TablePaginationActions';

import styles from './styleView.module.css';

import { api } from '../../../services/api';

import { isMobile } from '../../../utils/breakpoints';
import textCapitalize from '../../../utils/textCapitalize';
import brazilianCurrencyFormatter from '../../../utils/brazilianCurrencyFormatter';
import { ChipStatus } from '../../../components/ChipStatus';
import formatCPF from '../../../utils/formatCPF';
import { useEmployeesAprovedSituations } from './Hooks/useEmployeesAprovedSituations';

const PayrollDataReview = () => {
  const history = useHistory();

  const {
    handleEmployeeSituation,
    handleEmployeeSituationIcon,
  } = useEmployeesAprovedSituations();

  const importFileData = JSON.parse(sessionStorage.getItem('importFileData'));
  const paymentTypeDescription = JSON.parse(
    sessionStorage.getItem('paymentDescription'),
  );
  const authToken = sessionStorage.getItem('@ContaSoma-Token');
  const url = sessionStorage.getItem('urlPayroll');
  const selectedEmployees = JSON.parse(
    sessionStorage.getItem('selectedEmployees'),
  );

  const [tableData, setTableData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [alreadyUploadModal, setAlreadyUploadModal] = useState(false);
  const [chargeAlertModal, setChargeAlertModal] = useState(false);
  const [changeSeetDataModal, setChangeSeetDataModal] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalTitle, setErrorModalTitle] = useState('');
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  const [errorAlreadyPayroll, setErrorAlreadyPayroll] = useState('');

  const [depositAccountEncodedKey, setDepositAccountEncodedKey] = useState('');

  const [resignedEmployees, setResignedEmployees] = useState([]);

  const { accounts } = useSelector(state => state.account);

  const depositAccountIdSelected = accounts?.map(account => {
    if (account.encodedKey === depositAccountEncodedKey) {
      return account.id;
    }
    return null;
  });

  const hasMoreThanOneDepositAccount = accounts.length > 1;

  const {
    companyName,
    scheduleDate,
    paymentsAmount,
    fileEncodedKey,
    totalFeeAmount,
    totalAmount,
    fileName,
    signatureAmount,
    chargeSignature,
    signatureChargeMessage,
  } = importFileData;

  useEffect(() => {
    if (chargeSignature) setChargeAlertModal(true);

    if (history.location.state?.length > 0)
      setResignedEmployees(history.location.state);
  }, []);

  const amount = totalAmount?.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });

  const signatureValue = signatureAmount?.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });

  const payrollAmount = paymentsAmount?.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });

  const fee = totalFeeAmount?.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization: authToken,
      },
      params: {
        page: currentPage,
        size: pageSize,
        direction: 'ASC',
        sort: 'name',
      },
    };
    api
      .get(`/employeesPayments/draft/${fileEncodedKey}`, requestOptions)
      .then(({ data }) => {
        setTableData(data.content);
        setTotalSize(data.totalSize);
        setDepositAccountEncodedKey(data.depositAccount);
        setLoading(false);
      });
  }, [fileEncodedKey, currentPage, pageSize]);

  const onBackClicked = () => {
    if (isMobile) {
      history.push(url);
    } else {
      importFileData?.isFileImport
        ? history.push('/payroll/menu/info-payroll/register-batch')
        : history.push('/payroll/manual-payroll');
    }
  };

  const onNextClicked = () => {
    setLoadingButton(true);

    const requestOptions = {
      headers: {
        Authorization: authToken,
      },
    };
    api
      .post(
        `/files/employeespayments/confirmAppointment/${fileEncodedKey}`,
        '',
        requestOptions,
      )
      .then(({ data }) => {
        sessionStorage.setItem(
          'useTopBar',
          JSON.stringify({
            name: 'Pagamento manual',
            step: 'lastStep',
            route: '/payroll',
          }),
        );

        sessionStorage.setItem('importFileNsu', data.nsu);
        history.push('/payroll/success');
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          if (errors[0]?.errorReason === 'FILE_ALREADY_PROCESSED') {
            setErrorAlreadyPayroll(errors[0]?.errorsDescription.message);
            setAlreadyUploadModal(true);
          }

          setIsErrorModalOpen(true);
          setErrorModalTitle('Houve um erro ao agendar a folha');
          setErrorModalMessage(
            errors?.[0]?.errorDetail ??
              'Por favor, tente novamente mais tarde ou entre em contato com o nosso atendimento no menu ao lado.',
          );
        },
      )
      .finally(() => setLoadingButton(false));
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <div>
      {isMobile && (
        <Typography className={styles.titleStyle}>
          Confirme o agendamento
        </Typography>
      )}

      {!isMobile && (
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography className={styles.titleStyle}>
              {importFileData?.isFileImport
                ? 'Confirme o agendamento desta folha'
                : 'Pagamento manual'}
            </Typography>
          </Grid>

          {!importFileData?.isFileImport && (
            <Grid item md={8} lg={8}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>
                  <ProgressItem
                    title="Tipo e data"
                    icon={<CheckRoundedIcon className={styles.progressIcon} />}
                  />
                </Grid>

                <Grid className={styles.horizontalLine} />

                <Grid item>
                  <ProgressItem
                    title={
                      <span>
                        Funcionários e<br /> valores
                      </span>
                    }
                    icon={<CheckRoundedIcon className={styles.progressIcon} />}
                  />
                </Grid>

                <Grid className={styles.horizontalLine} />

                <Grid item>
                  <Box mr={6}>
                    <ProgressItem
                      title={
                        <span>
                          Confirmar <br /> e agendar
                        </span>
                      }
                      icon={
                        <CheckBoxRoundedIcon className={styles.progressIcon} />
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      {!importFileData?.isFileImport && !isMobile && (
        <>
          <div className={styles.divider} />
        </>
      )}

      <Box mb={importFileData?.isFileImport ? 2 : 0.5} />

      <div className={styles.contentStyle}>
        <InfoGrid
          companyName={companyName}
          scheduleDate={scheduleDate}
          totalAmount={amount}
          payrollAmount={payrollAmount}
          fee={fee}
          fileName={fileName}
          data={tableData}
          loading={loading}
          paymentTypeDescription={
            paymentTypeDescription?.paymentTypeDescription
          }
          title="As informações abaixo estão corretas?"
          signatureValue={signatureValue}
          chargeSignature={chargeSignature}
          setChangeSeetDataModal={setChangeSeetDataModal}
          date={`${moment(paymentTypeDescription?.date).format('YYYY-MM-DD')}T${
            paymentTypeDescription?.paymentHour
          }`}
          depositAccount={depositAccountIdSelected}
          hasMoreThanOneDepositAccount={hasMoreThanOneDepositAccount}
        />

        {resignedEmployees?.length > 0 && (
          <Box className={styles.warningBoxOfFiredEmployees}>
            <WarningRounded className={styles.warningIcon} />
            {resignedEmployees?.length === 1 ? (
              <Box flexDirection="column">
                <Typography className={styles.warningMessage}>
                  1 funcionário demitido foi incluído na folha de pagamento.
                </Typography>
                <Typography className={styles.warningMessage}>
                  O funcionário demitido estará destacado na lista abaixo.
                </Typography>
              </Box>
            ) : (
              <Box flexDirection="column">
                <Typography className={styles.warningMessage}>
                  {resignedEmployees?.length} funcionários demitidos foram
                  incluídos na folha de pagamento.
                </Typography>
                <Typography className={styles.warningMessage}>
                  Os funcionários demitidos estarão destacados na lista abaixo.
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {isMobile ? (
          selectedEmployees?.map(item => (
            <div className={styles.card}>
              <div>
                <Typography className={styles.text}>
                  {textCapitalize(item.name)}
                </Typography>

                <Typography className={styles.text}>
                  {formatCPF(item?.cpf)}
                </Typography>

                {!item?.hasBankData && (
                  <ChipStatus
                    icon={handleEmployeeSituationIcon(
                      item?.companies[0]?.status,
                    )}
                    status={handleEmployeeSituation(item?.companies[0]?.status)}
                    label={item?.companies[0]?.statusDescription}
                    size="medium"
                  />
                )}

                <Typography className={styles.text}>
                  {item?.amount
                    ? brazilianCurrencyFormatter(item?.amount)
                    : 'R$ 0,00'}
                </Typography>
              </div>
            </div>
          ))
        ) : (
          <Table
            data={tableData}
            loading={loading}
            currentPage={currentPage}
            pageSize={pageSize}
            totalSize={totalSize}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            TablePaginationActions={TablePaginationActions}
          />
        )}

        <div
          className={isMobile ? styles.continueButtonContainer : styles.hidden}
        >
          <Button className={styles.continueButton} onClick={onNextClicked}>
            {loadingButton ? (
              <CircularProgress className={styles.continueButtonLoader} />
            ) : (
              'Confirmar e agendar'
            )}
          </Button>
        </div>

        <AlreadyUploadModal
          open={alreadyUploadModal}
          onConfirmClicked={() => history.push('/payroll')}
          orangeConfirmButton
          confirmButtonText="Ver gestão de folha"
          title="Um outro usuário já realizou está ação."
          text={errorAlreadyPayroll}
        />

        <ChargeAlertModal
          open={chargeAlertModal}
          title={signatureChargeMessage?.title}
          text={signatureChargeMessage?.message}
          confirmButtonText="Entendi"
          onConfirmClicked={() => setChargeAlertModal(false)}
        />

        <ChangeSeetDataModal
          open={changeSeetDataModal}
          onCloseDialog={() => setChangeSeetDataModal(false)}
        />

        <ErrorModal
          open={isErrorModalOpen}
          onConfirmClicked={() => {
            setIsErrorModalOpen(false);
          }}
          title={errorModalTitle}
          text={errorModalMessage}
          confirmButtonText="Entendi"
        />

        <ProgressToolBar
          loading={loadingButton}
          onBackClicked={onBackClicked}
          onNextClicked={onNextClicked}
          backButtonText="Voltar"
          nextButtonText="Confirmar e agendar"
          hidden={isMobile}
          orangeNextButton
        />
      </div>
    </div>
  );
};

export default PayrollDataReview;
