import moment from 'moment';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../services/api';

import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';
import unMask from '../../../utils/unmask';

const { success, error } = handleSnackbarStack();

const initialState = {
  isLoading: false,
  isFetching: false,
  isSending: false,
  currentStep: 0,
  requestId: '',
  phoneNumber: '',
  digitable: '',
  bankOptions: [],
  results: [],
  selectedResult: {},
  currentBill: {},
  currentTransfer: {},
  count: {
    approved: null,
    pending: null,
    rejected: null,
  },
  filters: {
    creationUser: '',
    initialDate: null,
    finalDate: null,
    transferType: '',
  },
  pagination: {
    page: 0,
    perPage: 5,
    length: 0,
  },
  current: {
    key: '',
    keyType: '',
    paymentDate: '',
    account: {
      bankName: '',
      accountNumber: '',
      branch: '',
      participant: '',
    },
    owner: {
      name: '',
      type: '',
      taxIdNumber: '',
    },
    value: 0.0,
    date: null,
    description: '',
  },
  form: {
    key: '',
    keyType: 'CPF_CNPJ',
    emv: '',
    cpfCnpj: '',
    account: '',
    accountType: 'CC',
    agency: '',
    recipientName: '',
    bank: null,
    paymentDate: moment().format('YYYY-MM-DD'),
    description: '',
    value: 0.0,
  },
  error: {
    hasError: false,
    message: '',
  },
  transferError: {
    hasError: false,
    message: '',
  },
  actionResults: {
    approval: {
      hasSuccess: false,
      message: '',
    },
    reproval: {
      hasSuccess: false,
      message: '',
    },
    reprocess: {
      hasSuccess: false,
      message: '',
    },
    auth: {
      hasSuccess: false,
      message: '',
    },
    bill: {
      hasSuccess: false,
      message: '',
    },
  },
};

const createCelcoinAccount = createAsyncThunk(
  'cashOut/createCelcoinAccount',
  async (_, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;

    return api
      .post(`/cashIn/celcoinAccount/create/${currentCompany.code}`)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      );
  },
);

const getBankOptions = createAsyncThunk(
  'cashOut/getBankOptions',
  async (_, { rejectWithValue }) =>
    api
      .get('accountCashIn/bank/findAll')
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue(error.response.data.errors[0].errorDetail),
      ),
);

const getRequestsCount = createAsyncThunk(
  'cashOut/getRequestsCount',
  async (_, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const {
      filters: { initialDate, finalDate, creationUser, transferType },
    } = getState().cashOut;

    const config = {
      params: {
        initialDate,
        finalDate,
        creationUser,
        transferType,
      },
    };

    return api
      .get(`/request/${currentCompany.code}/count`, config)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const getRequestsList = createAsyncThunk(
  'cashOut/getRequestsList',
  async ({ status }, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const {
      pagination: { page, perPage },
      filters,
    } = getState().cashOut;

    return api
      .post(
        `/request/${currentCompany.code}/search?status=${status}&page=${page}&size=${perPage}`,
        filters,
      )
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const setRequestApproved = createAsyncThunk(
  'cashOut/setRequestApproved',
  async ({ pin }, { getState, rejectWithValue }) => {
    const {
      requestId,
      selectedResult: { id },
    } = getState().cashOut;

    return api
      .post(`/request/approve/${id}?pin=${pin}&requestId=${requestId}`)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const setRequestRejected = createAsyncThunk(
  'cashOut/setRequestRejected',
  async ({ reason }, { getState, rejectWithValue }) => {
    const { id } = getState().cashOut.selectedResult;

    return api
      .post(`/request/reject`, { id, reason })
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const setRequestReprocessed = createAsyncThunk(
  'cashOut/setRequestReprocessed',
  async ({ pin }, { getState, rejectWithValue }) => {
    const {
      requestId,
      selectedResult: { id },
    } = getState().cashOut;

    return api
      .post(`/request/reprocess/${id}?pin=${pin}&requestId=${requestId}`)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const setAuthentication = createAsyncThunk(
  'cashOut/setAuthentication',
  async (_, { rejectWithValue }) => {
    return api
      .post(`/request/mfa?sms=true&brandingName=SOMAPAY`)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const getDetailsPixKey = createAsyncThunk(
  'cashOut/getByPixKey',
  async ({ key }, { getState, rejectWithValue }) => {
    const { origin } = getState().account.accountSelected;
    const { currentCompany } = getState().companies;
    const {
      form: { keyType },
    } = getState().cashOut;

    const requestConfig = {
      headers: {
        accept: 'application/json, text/plain',
        accountOrigin: origin,
      },
    };

    const body = {
      key: keyType === 'CPF_CNPJ' ? unMask(key) : key,
      payerId: currentCompany.cnpj,
    };

    return api
      .post('pix/info/dict', body, requestConfig)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const getDetailsPixCopyPaste = createAsyncThunk(
  'cashOut/getByPixCopyPaste',
  async ({ emv }, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const { origin } = getState().account.accountSelected;

    const requestConfig = {
      headers: {
        accept: 'application/json, text/plain',
        accountOrigin: origin,
      },
    };

    return api
      .post(
        'pix/info/copy-paste',
        { emv, payerId: currentCompany.cnpj },
        requestConfig,
      )
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const setPixConfirmation = createAsyncThunk(
  'cashOut/setPixConfirmation',
  async (_, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const {
      form: {
        value,
        keyType,
        bank,
        account,
        agency,
        cpfCnpj,
        accountType,
        recipientName,
        description,
      },
      current: { key, owner, account: ownerAccount },
      bankOptions,
    } = getState().cashOut;
    const { origin } = getState().account.accountSelected;

    const getBankName = bank =>
      bankOptions.find(({ code }) => code === bank)?.name;

    const requestConfig = {
      headers: {
        accept: 'application/json, text/plain',
        accountOrigin: origin,
      },
    };

    const body = {
      accountOrigin: origin,
      amount: value,
      bankName: !keyType ? getBankName(bank) : ownerAccount.bankName,
      creditParty: {
        key: key || null,
        bank: !keyType ? bank : ownerAccount.participant,
        account: !keyType
          ? account?.replace('-', '')
          : ownerAccount.accountNumber,
        branch: !keyType ? agency?.replace('-', '') : ownerAccount.branch,
        taxId: !keyType ? unMask(cpfCnpj) : owner.taxIdNumber,
        accountType,
        name: !keyType ? recipientName : owner.name,
      },
      description,
      payerCpf: currentCompany.cnpj,
      pixType: keyType,
      transferType: 'PIX',
    };

    return api
      .post(`/request/${currentCompany.code}/create`, body, requestConfig)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const getDetailsBill = createAsyncThunk(
  'cashOut/getDetailsBill',
  async ({ digitable }, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;

    const body = {
      params: {
        companyCode: currentCompany.code,
        companyCnpj: currentCompany.cnpj,
        digitable,
      },
    };

    return api
      .get('/bill-payment', body)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const setBillConfirmation = createAsyncThunk(
  'cashOut/setBillConfirmation',
  async (_, { getState, rejectWithValue }) => {
    const { origin } = getState().account.accountSelected;
    const { currentBill } = getState().cashOut;
    const { currentCompany } = getState().companies;

    const requestConfig = {
      headers: {
        accept: 'application/json, text/plain',
        accountOrigin: origin,
      },
    };

    const body = {
      amount: currentBill.finalAmount,
      billPaymentReceiverDocument: currentBill?.recipientDocument,
      billPaymentReceiverName: currentBill?.recipientName,
      bankName: currentBill?.bank,
      fineAmount: currentBill?.fineAmount,
      interestAmount: currentBill.interestAmount,
      discountAmount: currentBill.discountAmount,
      transferType: 'BILL_PAYMENT',
      digitable: currentBill.barCode,
      billPaymentDueDate: `${currentBill.dueDate}T20:00:00`,
    };

    return api
      .post(`/request/${currentCompany.code}/create`, body, requestConfig)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const getDetailsTransfer = createAsyncThunk(
  'cashOut/getDetailsTransfer',
  async ({ cnpj }, { getState, rejectWithValue }) => {
    const { origin } = getState().account.accountSelected;
    const { currentCompany } = getState().companies;

    const requestConfig = {
      headers: {
        accountOrigin: origin,
      },
    };

    return api
      .get(
        `/internalTransfer/cnpj/${unMask(cnpj)}?payerCnpj=${
          currentCompany.cnpj
        }`,
        requestConfig,
      )
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const setTransferConfirmation = createAsyncThunk(
  'cashOut/setTransferConfirmation',
  async (_, { getState, rejectWithValue }) => {
    const { origin, id } = getState().account.accountSelected;
    const { form, currentTransfer } = getState().cashOut;
    const { currentCompany } = getState().companies;

    const requestConfig = {
      headers: {
        accept: 'application/json, text/plain',
        accountOrigin: origin,
      },
    };

    const body = {
      amount: form.amount,
      bankName: 'SOMAPAY',
      creditParty: {
        taxId: currentTransfer.cnpj,
        name: currentTransfer.companyName,
        account: currentTransfer.account,
      },
      description: form?.description,
      payerCpf: currentCompany.cnpj,
      transferType: 'INTERNAL_TRANSFER',
      depositAccount: id,
    };

    return api
      .post(`/request/${currentCompany.code}/create`, body, requestConfig)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const CashOutSlice = createSlice({
  name: 'cashOut',
  initialState,
  reducers: {
    cleanup: state => {
      Object.keys(initialState).forEach(item => {
        state[item] = initialState[item];
      });
    },

    setFormState: (state, { payload }) => {
      state.form = {
        ...payload,
        paymentDate: moment(payload?.paymentDate).format('YYYY-MM-DD'),
      };
    },

    resetForm: state => {
      Object.keys(initialState.form).forEach(item => {
        state.form[item] = initialState.form[item];
      });
    },

    changeStep: (state, { payload }) => {
      state.currentStep = payload;
    },

    setFilters: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },

    resetFilters: state => {
      state.filters = initialState.filters;
    },

    changePage: (state, { payload }) => {
      state.pagination = {
        page: payload.page ?? state.pagination.page,
        perPage: payload.perPage ?? state.pagination.perPage,
        length: payload.length ?? state.pagination.length,
      };
    },

    setSelected: (state, { payload }) => {
      state.selectedResult = payload;
    },

    resetErrors: state => {
      state.actionResults = initialState.actionResults;
    },

    setDigitable: (state, { payload }) => {
      state.digitable = payload;
    },

    setBillDueDate: (state, { payload }) => {
      state.currentBill.dueDate = payload;
    },

    resetBillSuccess: state => {
      state.actionResults.bill = initialState.actionResults.bill;
    },
  },
  extraReducers: {
    [createCelcoinAccount.pending]: state => {
      state.isLoading = true;
    },
    [createCelcoinAccount.fulfilled]: (state, { payload: { data } }) => {
      state.isLoading = false;
    },
    [createCelcoinAccount.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload?.error ||
          'Houve um erro ao obter os dados da empresa. Por favor, tente novamente mais tarde.',
      };

      error(state.error.message);
    },

    [getRequestsList.pending]: state => {
      state.isLoading = true;
      state.results = [];
    },
    [getRequestsList.fulfilled]: (state, { payload: { data } }) => {
      state.isLoading = false;
      state.results = data?.content ?? [];
      state.pagination = {
        ...state.pagination,
        length: data.totalSize,
      };
    },
    [getRequestsList.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload?.error ||
          'Houve um erro ao obter a listagem de solicitações. Por favor, tente novamente mais tarde.',
      };

      error(state.error.message);
    },

    [getRequestsCount.pending]: state => {
      state.isLoading = true;
      state.count = initialState.count;
    },
    [getRequestsCount.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.count = payload.data;
    },
    [getRequestsCount.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload?.error ||
          'Houve um erro ao obter a contagem total de solicitações. Por favor, tente novamente mais tarde.',
      };

      error(state.error.message);
    },

    [setAuthentication.pending]: state => {
      state.isSending = true;
      state.actionResults.auth = {
        hasSuccess: false,
        message: '',
      };
    },
    [setAuthentication.fulfilled]: (state, { payload }) => {
      state.isSending = false;
      state.requestId = payload?.data.requestId;
      state.phoneNumber = payload?.data.phoneNumber;
      state.actionResults.auth = {
        hasSuccess: true,
        message: '',
      };
    },
    [setAuthentication.rejected]: (state, { payload }) => {
      state.isSending = false;

      state.actionResults.auth = {
        hasSuccess: false,
        message:
          payload?.error ||
          'Houve um erro ao gerar o código de autenticação para autorizar a solicitação. Por favor, tente novamente mais tarde.',
      };

      error(state.actionResults.auth.message);
    },

    [setRequestApproved.pending]: state => {
      state.isSending = true;
      state.actionResults.approval = {
        hasSuccess: false,
        message: '',
      };
    },
    [setRequestApproved.fulfilled]: state => {
      state.isSending = false;
      state.actionResults.approval = {
        hasSuccess: true,
        message: '',
      };

      success(
        state.selectedResult.numberOfApprovals ===
          state.selectedResult.numberOfRequiredApprovals - 1
          ? 'Autorização e transferência realizada com sucesso!'
          : 'Autorização realizada com sucesso!',
      );
    },
    [setRequestApproved.rejected]: (state, { payload }) => {
      state.isSending = false;
      state.actionResults.approval = {
        hasSuccess: false,
        message:
          payload?.error ||
          'Houve um erro ao aprovar a solicitação. Por favor, tente novamente mais tarde.',
      };
    },

    [setRequestRejected.pending]: state => {
      state.isSending = true;
    },
    [setRequestRejected.fulfilled]: state => {
      state.isSending = false;
      state.actionResults.reproval = {
        hasSuccess: true,
        message: '',
      };

      success('Solicitação reprovada com sucesso!');
    },
    [setRequestRejected.rejected]: (state, { payload }) => {
      state.isSending = false;
      state.actionResults.reproval = {
        hasSuccess: false,
        message:
          payload?.error ||
          'Houve um erro ao reprovar a solicitação. Por favor, tente novamente mais tarde.',
      };

      error(state.actionResults.reproval.message);
    },

    [setRequestReprocessed.pending]: state => {
      state.isSending = true;
      state.actionResults.reprocess = {
        hasSuccess: false,
        message: '',
      };
    },
    [setRequestReprocessed.fulfilled]: state => {
      state.isSending = false;
      state.actionResults.reprocess = {
        hasSuccess: true,
        message: '',
      };

      success('Transferência reenviada com sucesso!');
    },
    [setRequestReprocessed.rejected]: (state, { payload }) => {
      state.isSending = false;
      state.actionResults.reprocess = {
        hasSuccess: false,
        message:
          payload?.error ||
          'Houve um erro ao reprocessar a solicitação. Por favor, tente novamente mais tarde.',
      };
    },

    [getBankOptions.pending]: state => {
      state.isFetching = true;
      state.bankOptions = [];
    },
    [getBankOptions.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.bankOptions = payload.data;
    },
    [getBankOptions.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = {
        hasError: true,
        message:
          payload ||
          'Houve um erro ao obter as instituições bancárias. Por favor, tente novamente mais tarde.',
      };

      error(state.error.message);
    },

    [getDetailsPixKey.pending]: state => {
      state.isLoading = true;
      state.current = { ...initialState.current };
    },
    [getDetailsPixKey.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.currentStep = 1;
      state.error = initialState.error;
      state.current = {
        ...payload.data,
        date: state.form.paymentDate,
        description: state.form?.description,
      };
    },
    [getDetailsPixKey.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload?.error ||
          'Houve um erro ao obter os dados do recebedor. Por favor, tente novamente mais tarde.',
      };

      error(state.error.message);
    },

    [getDetailsPixCopyPaste.pending]: state => {
      state.isLoading = true;
      state.current = { ...initialState.current };
    },
    [getDetailsPixCopyPaste.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.currentStep = 1;
      state.error = initialState.error;
      state.current = {
        ...payload.data.dict,
        date: state.form.paymentDate,
        description: state.form?.description,
      };
    },
    [getDetailsPixCopyPaste.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload?.error ||
          'Houve um erro ao obter os dados do recebedor. Por favor, tente novamente mais tarde.',
      };

      error(state.error.message);
    },
    [setPixConfirmation.pending]: state => {
      state.isLoading = true;
      state.error = initialState.error;
    },
    [setPixConfirmation.fulfilled]: state => {
      state.isLoading = false;
      success('Solicitação Pix cadastrada com sucesso!');
    },
    [setPixConfirmation.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload?.error ||
          'Houve um erro ao tentar cadastrar a solicitação via Pix. Por favor, tente novamente mais tarde.',
      };

      error(state.error.message);
    },
    [getDetailsBill.pending]: state => {
      state.isLoading = true;
      state.actionResults.bill = {
        hasSuccess: false,
        message: '',
      };
    },
    [getDetailsBill.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.currentStep = 1;
      state.currentBill = payload?.data;
    },
    [getDetailsBill.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.actionResults.bill = {
        hasSuccess: false,
        message:
          payload?.error ||
          'Houve um erro ao tentar obter detalhes sobre o boleto. Por favor, tente novamente mais tarde.',
      };

      error(state.actionResults.bill.message);
    },
    [setBillConfirmation.pending]: state => {
      state.isSending = true;
      state.actionResults.bill = {
        hasSuccess: false,
        message: '',
      };
    },
    [setBillConfirmation.fulfilled]: state => {
      state.isSending = false;
      state.actionResults.bill.hasSuccess = true;
    },
    [setBillConfirmation.rejected]: (state, { payload }) => {
      state.isSending = false;
      state.actionResults.bill = {
        hasSuccess: false,
        message:
          payload?.error ||
          'Houve um erro ao tentar cadastrar a solicitação via boleto. Por favor, tente novamente mais tarde.',
      };

      error(state.actionResults.bill.message);
    },
    [getDetailsTransfer.pending]: state => {
      state.isSending = true;
      state.transferError = {
        hasError: false,
        message: '',
      };
    },
    [getDetailsTransfer.fulfilled]: (state, { payload }) => {
      state.isSending = false;
      state.currentStep = 1;
      state.currentTransfer = payload?.data;
    },
    [getDetailsTransfer.rejected]: (state, { payload }) => {
      state.isSending = false;
      state.transferError = {
        hasError: true,
        message:
          payload?.error ||
          'Houve um erro ao tentar obter os detalhes do recebedor. Por favor, tente novamente mais tarde.',
      };

      error(state.transferError.message);
    },
    [setTransferConfirmation.pending]: state => {
      state.isSending = true;
      state.transferError = {
        hasError: false,
        message: '',
      };
    },
    [setTransferConfirmation.fulfilled]: state => {
      state.isSending = false;
      success('Solicitação de transferência cadastrada com sucesso!');
    },
    [setTransferConfirmation.rejected]: (state, { payload }) => {
      state.isSending = false;

      state.transferError = {
        hasError: false,
        message:
          payload?.error ||
          'Houve um erro ao tentar cadastrar a solicitação via boleto. Por favor, tente novamente mais tarde.',
      };

      error(state.transferError.message);
    },
  },
});

const cashOutReducer = CashOutSlice.reducer;
const CashOutActions = {
  ...CashOutSlice.actions,
  createCelcoinAccount,
  getRequestsList,
  getRequestsCount,
  setRequestApproved,
  setRequestRejected,
  setRequestReprocessed,
  setAuthentication,
  getBankOptions,
  getDetailsPixKey,
  getDetailsPixCopyPaste,
  setPixConfirmation,
  getDetailsBill,
  setBillConfirmation,
  getDetailsTransfer,
  setTransferConfirmation,
};

export { cashOutReducer, CashOutActions };
