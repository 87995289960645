export const itemsUrls = [
  {
    itemName: 'generalOverview',
    pathname: '/',
  },
  {
    itemName: 'cashIn',
    pathname: '/cash-in',
  },
  {
    itemName: 'cashIn',
    pathname: '/cash-in/inform-transfer-Deposit',
  },
  {
    itemName: 'cashIn',
    pathname: '/cash-in/new/billing',
  },
  {
    itemName: 'payment',
    pathname: '/payroll',
  },
  {
    itemName: 'payment',
    pathname: '/payroll/details',
  },
  {
    itemName: 'payment',
    pathname: '/payroll/manual-payroll',
  },
  {
    itemName: 'payment',
    pathname: '/payroll/menu',
  },
  {
    itemName: 'payment',
    pathname: '/payroll/menu/info-payroll/register-batch',
  },
  {
    itemName: 'payment',
    pathname: '/payroll/menu/info-payroll/register-batch/data-review',
  },
  {
    itemName: 'payment',
    pathname: '/payroll/success',
  },
  {
    itemName: 'payment',
    pathname: '/payroll/menu/info-payroll/register-batch/list-error',
  },
  { itemName: 'payment', pathname: '/payroll/file-batch/upload' },
  { itemName: 'payment', pathname: '/payroll/file-batch/confirm' },
  {
    itemName: 'bankExtract',
    pathname: '/extract',
  },
  {
    itemName: 'manageAccounts',
    pathname: '/manage-registered-accounts',
  },
  {
    itemName: 'pix',
    pathname: '/cash-in/pix',
  },
  {
    itemName: 'manageRequests',
    pathname: '/cash-out/manage',
  },
  {
    itemName: 'manageRequests',
    pathname: '/cash-out/pix',
  },
  {
    itemName: 'manageRequests',
    pathname: '/cash-out/transfer',
  },
  {
    itemName: 'manageRequests',
    pathname: '/cash-out/bill',
  },
  {
    itemName: 'valuesQuery',
    pathname: '/consolidated-values',
  },
  {
    itemName: 'manageEmployees',
    pathname: '/employees',
  },
  {
    itemName: 'manageEmployees',
    pathname: '/employees/register',
  },
  {
    itemName: 'manageEmployees',
    pathname: '/employees/edit',
  },
  {
    itemName: 'manageEmployees',
    pathname: '/employees/register-single',
  },
  {
    itemName: 'manageEmployees',
    pathname: '/employees/register-single/form/success',
  },
  {
    itemName: 'manageEmployees',
    pathname: '/employees/register-batch/menu',
  },
  {
    itemName: 'manageEmployees',
    pathname: '/employees/register-batch/menu/select-company/excel-form',
  },
  {
    itemName: 'manageEmployees',
    pathname:
      '/employees/register-batch/menu/select-company/excel-form/employee-list',
  },
  {
    itemName: 'registerFiles',
    pathname: '/employees/register-files',
  },
  {
    itemName: 'registerFiles',
    pathname: '/employees/register-files/details',
  },
  {
    itemName: 'reprovedOnboardings',
    pathname: '/employees/reproved-onboarding',
  },
  {
    itemName: 'paycheck',
    pathname: '/paycheck',
  },
  {
    itemName: 'valuesAnticipation',
    pathname: '/anticipation-values',
  },
  {
    itemName: 'limitQuery',
    pathname: '/limit-query',
  },
  {
    itemName: 'costCenterAndSector',
    pathname: '/cost-and-sector-center',
  },
  {
    itemName: 'myPlanSomapay',
    pathname: '/my-plan',
  },
  {
    itemName: 'collaborators',
    pathname: '/online-point-employees',
  },
  {
    itemName: 'myPoint',
    pathname: '/online-point-plan',
  },
  {
    itemName: 'invoices',
    pathname: '/online-point-charges',
  },
  {
    itemName: 'insurance',
    pathname: '/insurance-management',
  },
  {
    itemName: 'consignedProposes',
    pathname: '/consigned/proposes',
  },
  { itemName: 'helpCenter', pathname: '/help-center' },
  {
    itemName: 'beevaleBenefitsEmployee',
    pathname: '/beevaleBenefits/employee',
  },
  {
    itemName: 'beevaleBenefitsRecharge',
    pathname: '/beevaleBenefits/recharge',
  },
  { itemName: 'accessSettings', pathname: '/access-settings' },
];
