import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Card from './Card';
import Title from '../../../../components/Title';
import ErrorModal from '../../../../components/PrimaryModal';
import Footer from '../../../../components/AnimatedBottomBar';
import formatOnlyNumbers from '../../../../utils/formatOnlyNumbers';

import { api } from '../../../../services/api';

import { CashOutActions } from '../../../../redux/store/CashOut';

import { isMobile } from '../../../../utils/breakpoints';

export default function SetValue() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { accountSelected } = useSelector(state => state.account);

  const [pixValue, setPixValue] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const CURRENT_COMPANY_CODE = sessionStorage.getItem('currentCompanyCode');
  const INPUT_VALIDATE = Boolean(
    (pixValue > 0 && pixValue < 2000) || pixValue > 900000000,
  );

  const goBack = () => {
    if (isMobile) {
      removeMobileTopBar();
    }

    history.push('/cash-in');
  };

  const generateQRCode = () => {
    setLoading(true);

    const requestBody = {
      value: pixValue / 100,
      description: notes,
    };

    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
        accountOrigin: accountSelected?.origin,
      },
    };

    api
      .post(`/cashIn/pix/${CURRENT_COMPANY_CODE}`, requestBody, requestOptions)
      .then(({ data }) => {
        history.push({
          pathname: '/cash-in/pix/qrcode',
          state: {
            expirationDate: data.expirationDate,
            base64Qrcode: data.base64Qrcode,
            copyPasteCode: data.copyPasteCode,
            pixValue,
            notes,
          },
        });

        if (isMobile) {
          sessionStorage.setItem(
            'useTopBar',
            JSON.stringify({
              name: 'Depósito via Pix',
              step: 'lastStep',
              route: '/cash-in',
            }),
          );
        }
      })
      .catch(() => setOpenErrorModal(true))
      .finally(() => setLoading(false));
  };

  const handleChangePixValue = event => {
    setPixValue(formatOnlyNumbers(event.target.value));
  };

  const handleChangeNotes = event => {
    setNotes(event.target.value);
  };

  const removeMobileTopBar = () => {
    sessionStorage.removeItem('useTopBar');
  };

  useEffect(() => {
    dispatch(CashOutActions.createCelcoinAccount());
  }, []);

  return (
    <>
      <Title>Novo Depósito via Pix</Title>
      <Card
        pixValue={pixValue}
        notes={notes}
        handleChangePixValue={handleChangePixValue}
        handleChangeNotes={handleChangeNotes}
        INPUT_VALIDATE={INPUT_VALIDATE}
        loading={loading}
      />
      <ErrorModal
        open={openErrorModal}
        title="Tivemos um problema ao gerar o QR Code, tente novamente mais tarde"
        confirmButtonText="Entendi"
        onConfirmClicked={() => setOpenErrorModal(false)}
      />
      <Footer
        confirmButtonText="Gerar QR Code"
        onConfirm={generateQRCode}
        onCancel={goBack}
        disabledNextButton={!pixValue || INPUT_VALIDATE || loading}
        loading={loading}
      />
    </>
  );
}
