import React from 'react';
import { ModalDef } from '@ebay/nice-modal-react';
import { PayrollAuthorizationModal } from './PayrollAuthorizationModal';
import { InsufficientFundsModal } from './InsufficientFundsModal';

export function PayrollModals() {
  return (
    <>
      <ModalDef
        id="payroll-authorization-modal"
        component={PayrollAuthorizationModal}
      />

      <ModalDef
        id="insufficient-funds-modal"
        component={InsufficientFundsModal}
      />
    </>
  );
}
