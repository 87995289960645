import React from 'react';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Grid,
  Typography,
  Button,
  Snackbar,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import MuiAlert from '@material-ui/lab/Alert';

import styles from './styles.module.css';

import brazilianCurrencyFormatter2 from '../../../../utils/brazilianCurrencyFormatter2';

export default function Card({
  result,
  modalSucessCopyPix,
  setModalSucessCopyPix,
}) {
  const {
    expirationDate,
    base64Qrcode,
    copyPasteCode,
    pixValue,
    notes,
  } = result;

  const COMPANIES = JSON.parse(sessionStorage.getItem('companies'));
  const COMPANY_INDEX = JSON.parse(
    sessionStorage.getItem('currentCompanyIndex'),
  );
  const COMPANY_NAME = COMPANIES[COMPANY_INDEX]?.name
    ? COMPANIES[COMPANY_INDEX].name
    : COMPANIES[0].name;

  const QRCode = ({ data }) => (
    <img src={`data:image/jpeg;base64,${data}`} alt="qrcode" className={styles.qrCode}  />
  );

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <Grid className={styles.card}>
      <Typography className={styles.bigText}>Informações do Pix:</Typography>

      <Grid container spacing={3}>
        <Grid item>
          <Typography className={styles.label}>Valor</Typography>
          <Typography className={styles.defaultText}>
            {brazilianCurrencyFormatter2(pixValue)}
          </Typography>
        </Grid>

        <Grid item>
          <Typography className={styles.label}>Empresa</Typography>
          <Typography className={styles.defaultText}>{COMPANY_NAME}</Typography>
        </Grid>

        <Grid item>
          <Typography className={styles.label}>Validade</Typography>
          <Typography className={styles.defaultText}>
            {moment(expirationDate).format('DD/MM/YYYY')}
          </Typography>
        </Grid>

        {notes && (
          <Grid item xs={12}>
            <Typography className={styles.label}>Observações</Typography>
            <Typography className={styles.defaultText}>{notes}</Typography>
          </Grid>
        )}

        <Tooltip title="Copiar código pix" placement="top">
          <CopyToClipboard text={copyPasteCode}>
            <Button
              className={styles.nsuNumberCopy}
              onClick={() => setModalSucessCopyPix(true)}
            >
              <IconButton>
                <FileCopyOutlinedIcon className={styles.copyNsuIcon} />
              </IconButton>
              Pague com o código pix
            </Button>
          </CopyToClipboard>
        </Tooltip>

        <Grid item md={12} lg={12} className={styles.qrCodeGrid}>
          {/* <div className={styles.qrCode}> */}
          <QRCode data={base64Qrcode} className={styles.qrCode} />
          {/* </div> */}
        </Grid>
      </Grid>

      <Snackbar
        open={modalSucessCopyPix}
        autoHideDuration={3000}
        onClose={() => setModalSucessCopyPix(false)}
      >
        <Alert onClose={() => setModalSucessCopyPix(false)} severity="success">
          Código pix copiado com sucesso
        </Alert>
      </Snackbar>
    </Grid>
  );
}
